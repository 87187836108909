@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* @import url('https://abeeventos.s3.sa-east-1.amazonaws.com/ClashDisplay-Bold.otf'); */
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ClashDisplay-Bold';
  src: url('https://abeeventos.s3.sa-east-1.amazonaws.com/clashfont/ClashDisplay-Bold.woff2') format('woff2'),
       url('https://abeeventos.s3.sa-east-1.amazonaws.com/clashfont/ClashDisplay-Bold.woff') format('woff'),
       url('https://abeeventos.s3.sa-east-1.amazonaws.com/clashfont/ClashDisplay-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}

body {
  overflow-x: hidden;
  margin: 0;
  background-color: black;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-clip-style {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
  -webkit-text-fill-color: transparent !important;
}

.leagueSpartanFont {
  font-family: 'League Spartan';
}

.clash-display {
  font-family: 'ClashDisplay-Bold';
  font-weight: 700;
}

.swiper-slide {
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

button {
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-focus-ring-color: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

.headerlink {
  font-family: 'League Spartan', sans-serif;
  font-size: 3xl;
}

.headerlink:hover, .headerlink:focus, .headerlink:active {
  outline: none;
  background-color: transparent !important;
}

.headerlink:focus {
  outline: none;
  background-color: transparent !important;
}

.clients-slider .swiper-slide {
  transition: transform 0.3s;
  transform: scale(0.75) !important;
}

.clients-slider .swiper-slide-active {
  transform: scale(1) !important;
}

.swiper-custom-bullet {
  height: 2px;
  width: 2px;
  background-color: white;
}

.swiper-custom-bullet.swiper-custom-bullet-active {
  height: 2px;
  width: 2px;
  background-color: orange;
}

button, a {
  user-select: none;
}

/* Para Chrome, Safari, Edge */
* {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #333 #1a1a1a; /* Thumb e track para Firefox, mais escuros */
}

/* Para Chrome, Edge, e Safari */
*::-webkit-scrollbar {
  width: 10px; /* Largura da scrollbar */
}

*::-webkit-scrollbar-track {
  background: #1a1a1a; /* Cor de fundo do track, bem escuro */
}

*::-webkit-scrollbar-thumb {
  background-color: #333; /* Cor do thumb, escuro */
  border-radius: 5px; /* Arredondamento do thumb */
  border: 2px solid #1a1a1a; /* Borda escura para evitar qualquer borda clara visível */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Cor do thumb ao hover, um pouco mais clara */
}