@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

button{
  transition: box-shadow 100ms;
}

button:hover{
  box-shadow: 0px 0px 5px 0px #FFA41C;
}

.roboto {
  font-family: 'Roboto', serif !important;
}

.simple-button:hover{
  box-shadow: none;
}

.see-more-button{
  transition: box-shadow 100ms, transform 100ms;
}

.see-more-button:hover{
  box-shadow: 0px 0px 5px 0px #FFF !important;
}


.event{
  border: 1px solid transparent;
  border-radius: 5px;
  transition: border 150ms;
}

.event:hover{
  border: 1px solid white;
}

.product-container{
  transition: box-shadow 100ms;
}

.product-container:hover{
  box-shadow: 0px 0px 6px 0px #FFF;
}

.info-button{
  border-radius: 50px;

  color: transparent;

  width: 25px;
  overflow: hidden;
  padding: 4px 4px;

  transition: width 200ms, color 200ms, border-radius;
}

.info-button:hover{
  border-radius: 3px;
  width: 100px;
  color: black;
  box-shadow: none;
}

.star-group{
  transition: filter 100ms;
}

.star-group:hover{
  filter: drop-shadow(0 0 5px #fff);
}